<template>
  <div>信箱驗證中，請稍後...</div>
</template>

<script>
export default {
  created() {
    this.verifyEmail()
  },
  methods: {
    // 信箱驗證
    verifyEmail() {
      const vm = this
      const verifyEmailApi = `${process.env.VUE_APP_API}/user/verifyEmail?token=${this.$route.query.token}`;
      $.ajax({
        type: 'GET',
        async: true,
        url: verifyEmailApi,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.$methods.delCookie("serverToken");
            vm.$methods.setCookie("serverToken", res.data);
            vm.$router.push("/user/linkAccounts");
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          alert(err.responseJSON.message)
        },
      });
    },
  },
}
</script>